.App{
    position: relative;
    min-height: 100vh;
}

@media screen and (max-width: 425px) {
    .App {
      // margin: 0 auto;
      // height: 100%
    }
  }