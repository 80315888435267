.mobilemenu{
    display: none;
}


@media only screen and (max-width: 425px) {
    .mobilemenu {
        position:fixed;
        display: flex;
        z-index: 1;
        width: 100%;
        height: 100%;
        .mobilemenu-background{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: .5;
        }
        .mobilemenu-container{
            z-index: 1;
            height: 400px;
            width: 300px;
            background-color: #333;
            color:white;
            opacity: 1;
            .mobilemenu-header{
                margin: 12px;
                font-size: 2em;
                button{
                    float: right;
                    background-color: transparent;
                    border: none;
                    color: white;
                    font-size: 30px;
                    width: 50px;
                    height: 40px;
                }
            }
            .mobilemenu-option{
                overflow:auto;
                display: flex;
                font-size: 25px;
                margin: 40px 20px;
                cursor: pointer;
                img{
                    margin-right: 25px;
                    width: 30px;
                }
            }
        }
    }
}